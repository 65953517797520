.admin-layout{
    width: 100%;
    display: flex;
    .sidebar{
    background-color: var(--background-color);
        // width: 240px;
        position: fixed;
        top: 60px;
        left: 0;
        bottom: 0;
        z-index: 5;
        box-shadow: 5px 4px 9px -0.5px #dddddd;
        .side-logo{
            height: 70px;
            width: 100%;
            padding: 20px 0px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: auto;
                width: 90px;
            }
        
        h3{
            color: var(--primary-color);
           
            margin-left: -20px;
            font-size: 16px;
            letter-spacing: 1.2px;

        }
        }
        
        .hr{
            height: 1px;
            width: 100%;
            background-color:  whitesmoke;

        }
        .admin-head-text{
            font-size: 12px;
            letter-spacing: 1.2px;
            color: grey;
        }
        .admin-sidebar-items{
            padding: 20px 0px;
            .admin-sidebar-item{
                display: flex;
                justify-content: start;
                flex-direction: row;
                padding: 5px 10px;
                width: 100%;
                align-items: center;
                color: white;
                font-size: 12px;
                .admin-sidebar-item-icon{
                    font-size: 30px;
                    margin-right: 10px;
                }
                .admin-sidebar-item-text{
                    margin-left: 10px;
                    font-weight: 600;
                    letter-spacing: 1.2px;
                    height: 100%;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    p{
                        margin-top: 16px;
                    }
                }
            }
        }
        
        a{
            text-decoration: none;
        color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        height: 100%;

        }
        
    }
    .sidebar.notmatch{
        width: 240px;
    }
    .sidebar.match{
        width: 240px;
        position: absolute;
        left: 0;
        z-index: 40;
        transition:left 0.3s ease-in-out;
    }

    .sidebar.match.none{
        // display: none;
        // width: -220px;
        left: -240px;
        transition: left 0.5s ease-in-out;


    }

    .main-cont{
       width: 100%;
    //    margin-left: 240px;
       .header{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;
        background-color:var(--primary-color);
        position: fixed;
        top: 0;
        right: 0;
        // left: 240px;
        box-shadow: 5px 4px 9px -0.5px #dddddd;
        z-index: 2;
        
       }

       .conta{
        width: 100%;
        // margin-left: 240px;
        min-height: 100vh;
        // padding-top: 70px;
        padding: 70px 12px 12px 12px;
       }

       .conta.ng{
        width: 100%;
        margin-left: 240px;

       
       }
    }

    .main-cont.notmatch{
        // width: calc(100vw - 240px);
        margin-left: -240px;
    }
    .main-cont.match{
        width: 100%;
      
    }
}
.blur{
    background: rgba($color: #000000, $alpha: 0.1);
    backdrop-filter: blur(2px);
    position: absolute;
    top: 65px;
    left: 0;
    height: 100%;
    width: 100%;
}

.admin-content{
    margin-left: 240px;
    width: calc(100vw - 240px);
}

.admin-content.ng{
    margin-left: 0px;
    width: calc(100vw - 0px);
}

.user-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 10px;
    border-radius: 5px;
}

.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown-content{
    // margin-right: 20px;
}

.dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #333;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}