.full-container, .container-fluid{
    background-color: var(--background-color) !important;
    min-height: 100vh;
    padding: 5px;
    overflow:hidden;
    

    header{
        border-bottom: 1px solid var(--white-color);
        display: flex;
        justify-content: center;
        nav{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 20px 0px 12px;
          
            
            .logo{
                margin-left: -20px;
                img{
                    width: 120px;
                }
                h3{
                    color: var(--primary-color);
                   
                    margin-left: -20px;
                    font-size: 24px;
                    font-weight: 900;
                    letter-spacing: 1.2px;

                }
                justify-content: flex-start;
            }

            ul{
                li{
                    span{
                        color: var(--primary-color);
                        font-size: 18px;
                        font-weight: 600;
                        &:hover{
                            text-decoration: 1px underline var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}

.top-gh{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px 12px 12px;

}

#bu{
    background: rgba(135,255,254, 0.6);
    color: white;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    font-weight: 900;
    font-size: 14px;

}

input,select{
    padding: 12px !important;
}

input::placeholder, select::placeholder{
    font-weight: 300;
}

.custom-control-label{
    background: rgba(135,255,254, 0.3);
    color: white;
    font-weight: 700;
    font-size: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor:pointer;

}


.custom-control-label-select{
    background: rgba(135,255,254, 1);
    color: black !important;
    font-weight: 700;
    font-size: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor:pointer;



}
.company-img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 12px;
}

.company-name{
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
    text-align: left;
    text-wrap: nowrap;
}
table{
    tr{
        th{
          
            text-transform: uppercase;
            font-size: 13px;
        }
    }
    tr{
        vertical-align: middle;
        td{
            font-size: 12px;
        }
    }
}
label{
    color: white;
    margin: 6px;
}

.custom-tab{
    border-bottom: 1px solid rgb(193, 193, 193);
    ul{
        display: flex;
        align-items: center;
        li{
            margin: 0px 12px;
            cursor:pointer;
        }
        li.non-activate{
            color: var(--white-color);
            font-size: 12px;
            font-weight: 800;
            &:hover{
            background: rgba(135,255,254, 0.3);
            padding: 6px;
            border-radius: 5px;
            padding: 12px;
            }
        }
        li.activate{
            color: var(--white-color);
            font-size: 12px;
            font-weight: 900;
            background: rgba(135,255,254, 0.4);
      
            border-radius: 5px;
            padding: 12px;

        }
    }
}


.css-eg0mwd-MuiSlider-thumb{
    height: 25px !important;
    width: 20px !important;
    border-radius: 5px !important;
    background-color: var(--primary-color) !important;
}

.css-14pt78w-MuiSlider-rail, .css-1gv0vcd-MuiSlider-track{
    background-color: var(--primary-color) !important;
    
}


.first,.second{
    color: white;
    font-weight: 700;

}

.first{
   
}

.horizontal-slider{
    background-color: white;
}

.title{
    color: var(--primary-color);
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;

}

.titlep{
    color: var(--primary-color);
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;

}
table#custom-table{
    tbody{
        tr{
            td:nth-child(-n + 3){
                color: white;
                
            }
            td:nth-child(4){
                color: var(--primary-color);
                
            }
        }
    }
}
table#custom-table1{
    tbody{
        tr{
            td:nth-child(-n + 2){
                color: white;
                
            }
            td:nth-child(3){
                color: var(--primary-color);
                
            }
        }
    }
}
table#custom-table2{
    tbody{
        tr{
            td:nth-child(-n + 3){
                color: white;
                
            }
            td:nth-child(4){
                color: var(--primary-color);
                
            }
        }
    }
}

.myBg{
    // background-image: url('../../assets/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.myRow{
    min-width: 80vw;
    max-width: 95vw;
    padding: 12px;
    background-color: rgba(47, 47, 45, 0.2);
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
    // grid-template-rows: 300px;
    gap: 20px;

    .co{

    }
}


.custom-card{
    h5{
        color: var(--primary-color);
    }
    .custom-box{
        border: 1px solid white;
        height: 300px;
        width: 100%;
        border-radius: 12px;
        display: flex;
        align-items: center;
        margin: 0px auto;

        .b{
            margin: 0 auto;
            p{
                margin: auto;
                color: white;
                font-size: 10px;
                text-transform: uppercase;
            }
            span{
                color: white;
                font-size: 10px;
            }
        }
        
    }
}

.box{
    width: 100%;
    margin-top: 30px;
   
    // align-items: center;
    margin: 0px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    
 
    gap: 20px;

    .item{
        // background-color: red;
        border: 1px solid white;
        border-radius: 12px;
        box-shadow: 0px 0px 2px #dddddd;
        padding: 12px;
       list-style: none;
        h2{
            color: var(--primary-color);
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
            text-align: center;
        }
        p{
            color: var(--white-color);
            line-height: 150%;
            font-weight: 500;
            font-size: 12px;
        }
        p:last-child{
            color: var(--primary-color);
            // font-weight: 500;
            line-height: 150%;
            text-align: center;
            font-weight: 600;
        }
    }
}

.bh{
    color: var(--primary-color);
    // font-weight: 500;
    line-height: 150%;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
}


.loading-spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #000000;
    border-right-color: #18dde4;
    animation: spinner-d3wgkg 1s infinite linear;
 }
 
 label{
    color: white;
 }
 @keyframes spinner-d3wgkg {
    to {
       transform: rotate(1turn);
    }
 }

 .typex{
     list-style: none;
     background: black;
     color: white;
     padding: 5px;
     border-radius:5px;
 }

 label#home-label{
    color: white;
 }
@media screen and (max-width: 960px) {
    .full-container{
        header{
            nav{
                width: 100vw;
                justify-content: space-between;
                padding: 0px 10px 0px 0px;
                .logo{
                    img{
                        width: 90px;
                    }
                    h3{
                        font-size: 18px;
                    }
                }
            }
        }
    }



    .top-gh{
        display: block;
        .li{
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 12px auto;
            cursor:pointer;

        }
    }
    #bu{
        background: var(--primary-color);
        color: white;
        width: 250px;
        margin: 12px auto;
      
    
    }
}


@media screen and (max-width:460px){
    #ds{
        display: block;
    }
    
}