@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  text-decoration: none;
  scroll-behavior: smooth;
  transition: 0.1s;
}
:root{
  --primary-color: #6bfefc;
  --background-color: #120f10;
  --white-color: #ffffff;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:Roboto, 'Oxygen',sans-serif;

  /* background-color: black; */
}

.spinner-box{
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: repeating-conic-gradient(#474bff 0 90deg,#dbdcef 0 180deg);
  animation: spinner-a78xsi 1s infinite linear;
}

@keyframes spinner-a78xsi {
  100% {
     transform: rotate(1turn);
  }
}

ul.dropdown-options{
  max-height: 200px;
  overflow: auto;

}
.jk{
  padding: 10px;
  border: 1px solid white;

  margin-left: 0px !important;
}
.jk:hover{
  background-color: #120f10;
  color: #ffffff;
  border-radius: 8px;
}