
.container-fluids{
    min-height: 100vh;
    background-color: var(--background-color);
}
.cardi{
    width: 500px;
    .logo{
        width: 100%;
        text-align: center;
     
        img{
            margin: auto;
        }
    }
    .card-body{
        form{
            .form-group{
                label{
                    font-size: 16px;
                    color: var(--white-color);
                    font-weight: 700;

                }
                input{
                    padding: 15px 12px;
                    border: 1px solid #bebebe;
                    outline: none;
                    border-radius: 5px;
                    color: black;
                }

                button{
                    width: 100%;
                    border-radius: 5px;
                    background-color: var(--primary-color);
                    color: white;
                    font-weight: 700;
                    font-size: 18px;
                    padding: 12px;
                }
            }
        }
    }
}

p.reset{
    marging: 20px 0px;
    color:white;
    font-size: 14px;
    span{
        color: var(--primary-color);
        text-decoration: underline;

        &:hover{
            cursor: pointer;
        }
    }
}