table {
    thead {
        tr {
            text-align: center;
            font-size: 12px;
            vertical-align: middle;
        }
    }

    tbody {
        font-size: 12px;

        tr {
            text-align: center;
        }
    }
}

.form-group{
    label{
        color: black;
    }
}

.img-preview{
    height: 80px;
    width: 80px;
    border-radius: 12px;
    overflow: hidden;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.p_image{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
    img{

        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

label[htmlFor="publication_name"]{
    color: black !important;
}